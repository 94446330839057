import styled from "styled-components";
import { ReactComponent as CheckIcon } from "svg/check-mark.svg";
import React, { useContext, useState, useRef } from "react";
import { EventContext, AnalyticsContext, UserContext } from "context";

export const Checkbox = ({
  onChange,
  title,
  checked,
  disabled,
  hideCheck = false,
  error,
  ...props
}) => {
  const handleChange = (e) => {
    const {
      target: { checked },
    } = e;
    return onChange(checked, e);
  };

  const { logVideoAction, logClick } = useContext(AnalyticsContext);

  const linkClickHandler = (url, label, category) => {
    console.log("LABELS", url, label, category)
    logClick({ label, url }, category);
  };

  return (
    <Wrapper>
      <CheckboxContainer>
        <HiddenCheckbox
          checked={checked}
          onChange={handleChange}
          disabled={disabled}
          {...props}
        />
        {!hideCheck && (
          <StyledCheckbox checked={checked} error={error}>
            <Icon />
          </StyledCheckbox>
        )}
      </CheckboxContainer>
      {!!title && <StyledRadioTitle
      //  onClick={() => linkClickHandler("Elavon/MAX privacy policy", "Elavon/MAX privacy policy", "external")}
       >{title}</StyledRadioTitle>}
    </Wrapper>
  );
};

const primaryColor = "#3b3b3b";
const borderRadius = "0"
const errorBackground = "#EDB8B4";
const errorBorderColor = "#E45C52";

const Wrapper = styled.label`
  display: flex;
  padding: 0;
`;

const CheckboxContainer = styled.div`
  margin: 5px 20px 0 0;
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
`;

const Icon = styled(CheckIcon)`
  visibility: ${(props) => (props.checked ? "visible" : "hidden")};
`;

const StyledRadioTitle = styled.span`
  font-size: 11px;
  line-height: 1.5;
  color: ${primaryColor};

  & > a {
    color: ${primaryColor};
  }
 
`;

const StyledCheckbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => (props.checked ? "#ffffff" : "#ffffff")};
  border: 1px solid ${primaryColor};
  border-radius: ${borderRadius};
  width: 16px;
  height: 16px;

  input[disabled] + & {
    background: #dcdcdc;
    border-color: #dcdcdc;
  }

  ${Icon} {
    width: 18px;
    height: 18px;
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};

    path {
      fill: #000;
    }
  }

  ${(props) =>
    props.error &&
    `border: 2px solid ${errorBorderColor};
    background: ${errorBackground};`}
`;
