import React, { useContext, useState, useRef } from "react";
import { EventContext, AnalyticsContext, UserContext } from "context";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { PrimaryTemplate as Layout } from "Layout/Templates";
import { MaxWidthContainer } from "Layout/MaxWidthContainer";
import {
  Modal,
  Media16By9,
  MediaVideo,
  HeroVideo,
  ButtonPrimary,
} from "Components";
import { VideoPlayer } from "Components/Media/VideoPlayer";
import { RegistrationForm } from "Components/Form";
import { DateTime } from "luxon";
import { firebase } from "context";
import { Instagram } from "Components/svgs/Instagram";
import { Facebook } from "Components/svgs/Facebook";
import { Google } from "Components/svgs/Google";
import { Linkedin } from "Components/svgs/Linkedin";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BusinessSection from "Components/BusinessSection";
import { useMediaQuery } from "hooks/useMediaQuery";

export const RegistrationLayout = React.memo(
  ({
    sweeps,
    postSweeps,
    ineligible,
    formStep = 0,
    registered,
    postCampaign,
    setIneligible,
  }) => {
    const { event } = useContext(EventContext);
    const { logVideoAction, logClick } = useContext(AnalyticsContext);
    const { user } = useContext(UserContext);

    const [open, setOpen] = useState(false);
    const [video, setVideo] = useState(null);
    const [shareBtn, setShareBtn] = useState("Share Web Link");

    const [entry, loading] = useDocumentData(
      firebase.firestore().doc(`events/${event?.id}/entries/${user?.uid}`)
    );
    const formScreen = useRef();
    const isMobile = useMediaQuery();

    if (loading) {
      return null;
    }

    const registerTime = DateTime.fromFormat(
      "10/09/2019 10:03:00.000 AM",
      "D hh:mm:ss.SSS a"
    ).toISO();

    const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
    const storageDirectory = event.meta.storageDirectory;
    // logo
    const { brand: brandLogo } = event.logos;
    let brandLogoImg = `${api}/o/${storageDirectory}%2F${brandLogo?.image}?alt=media`;

    if (registered) formStep = event.register.sweeps.length - 1;
    // registration content
    const {
      heading,
      subHeading,
      body,
      formHeading,
      formLegal,
      brandLegal,
      submit,
      iconInst,
      iconFB,
    } = event.register.sweeps[formStep];

    // brand cta
    const { heroLearnMore, instagram, shareLink } = event.callToActions.links;

    //images
    const { images } = event.content;
    let imgAtlanta = `${api}/o/${storageDirectory}%2F${images.atlanta}?alt=media`;
    let imgArtist = `${api}/o/${storageDirectory}%2F${images.artist}?alt=media`;
    let img3 = `${api}/o/${storageDirectory}%2F${images.img3}?alt=media`;
    let img4 = `${api}/o/${storageDirectory}%2F${images.img4}?alt=media`;

    //bg-images
    const { backgrounds } = event.content;
    let bg1 = `${api}/o/${storageDirectory}%2F${backgrounds.bg1}?alt=media`;
    let bg2 = `${api}/o/${storageDirectory}%2F${backgrounds.bg2}?alt=media`;
    let bg = `${api}/o/${storageDirectory}%2F${backgrounds.bg}?alt=media`;
    let bgMob = `${api}/o/${storageDirectory}%2F${backgrounds.bgMob}?alt=media`;

    //business-images
    const { business } = event.content;
    let b1 = `${api}/o/${storageDirectory}%2F${business.b1}?alt=media`;
    let b2 = `${api}/o/${storageDirectory}%2F${business.b2}?alt=media`;
    let b3 = `${api}/o/${storageDirectory}%2F${business.b3}?alt=media`;
    let b4 = `${api}/o/${storageDirectory}%2F${business.b4}?alt=media`;
    let b5 = `${api}/o/${storageDirectory}%2F${business.b5}?alt=media`;
    let b6 = `${api}/o/${storageDirectory}%2F${business.b6}?alt=media`;

    // brand cta background
    const {
      desktop: brandBgD,
      mobile: brandBgM,
      heading: CTABrandHeading,
      brandImage,
      btnLink: brandCTALink,
    } = event?.callToActions?.brand?.background;
    let CTABrandBDesktop = `${api}/o/${storageDirectory}%2F${brandBgD}?alt=media`;
    let CTABrandImg = `${api}/o/${storageDirectory}%2F${CTABrandHeading}?alt=media`;
    let brandBgMobile = `${api}/o/${storageDirectory}%2F${brandBgM}?alt=media`;
    let brandImgDesktop = `${api}/o/${storageDirectory}%2F${brandImage}?alt=media`;

    // brand product background
    const {
      desktop: brandProductDesktop,
      mobile: brandProductMobile,
    } = event?.callToActions?.brand?.product;
    let productDesktop = `${api}/o/${storageDirectory}%2F${brandProductDesktop}?alt=media`;
    let productMobile = `${api}/o/${storageDirectory}%2F${brandProductMobile}?alt=media`;

    // artist background
    const {
      desktop: artistPicDesktop,
      formImg,
      CTAbackground,
      artistCTALink,
      heading: artistCTAHeading,
      // mobile: artistMobile,
    } = event?.callToActions?.artist;
    let artistDesktop = `${api}/o/${storageDirectory}%2F${artistPicDesktop}?alt=media`;
    let CTAArtistImg = `${api}/o/${storageDirectory}%2F${artistCTAHeading}?alt=media`;
    // let productMobile = `${api}/o/${storageDirectory}%2F${brandProductMobile}?alt=media`;
    let CTAArtistDesktop = `${api}/o/${storageDirectory}%2F${CTAbackground}?alt=media`;
    let formImgDesktop = `${api}/o/${storageDirectory}%2F${formImg}?alt=media`;

    const desktopVideoUrl = `https://storage.googleapis.com/whitelabel-set-live.appspot.com/658-elavon-musiq-soulchild-2023/desktop/manifest.m3u8`;
    const mobileVideoUrl = `https://storage.googleapis.com/whitelabel-set-live.appspot.com/658-elavon-musiq-soulchild-2023/mobile/manifest.m3u8`;

    const currentVideoUrl = isMobile ? mobileVideoUrl : desktopVideoUrl;

    // post-campaign content
    const {
      heading: postCampaignHeading,
      body: postCampaignBody,
    } = event.postCampaign;

    // brand cta (post-campaign)
    const {
      btnLink: brandSecondaryCTALink,
      btnText: brandSecondaryCTAText,
      heading: brandSecondaryCTAHeading,
    } = event.callToActions.brandSecondary;

    const linkClickHandler = (url, label, category) => {
      logClick({ label, url }, category);
    };

    const openInNewTab = (url) => {
      window.open(url, "_blank", "noopener,noreferrer");
    };

    const copyLinkHandler = () => {
      navigator.clipboard.writeText("gildanmusic.com/chris-lane");
      setShareBtn("Copied!");
      setTimeout(() => {
        setShareBtn("Share Web Link");
      }, 2000);
    };

    const setModalVisible = (bool, link) => {
      setVideo(link);
      setOpen(bool);
      logVideoAction({ bool, videoLink: link, videoTitle: "Hero Video" });
    };

    const scrollToSection = (elementRef) => {
      elementRef.current.scrollIntoView();
    };

    return (
      <Layout
        registration={postCampaign ? false : true}
        flexGrow
        showHeader
        showFooter
        postCampaign={postCampaign}
      >
        <Container postCampaign={postCampaign}>
          <TitleContainer>
            <Title>
              Open for
              <br />
              Small Business
            </Title>
          </TitleContainer>
          <VideoOverlay />
          {!registered && !postCampaign && (
            <FormSection>
              <VideoContainer
                play
                path={currentVideoUrl}
                loop
                mute
                thumbnail={isMobile ? bgMob : bg}
                isBackground
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  maxWidth: "100%",
                  maxHeight: "50%",
                  width: "100%",
                }}
              />
              <FormContainer>
                <RegistrationForm
                  formPart={formStep}
                  formHeading={formHeading}
                  formLegal={formLegal}
                  brandLegal={brandLegal}
                  // formPolicy={formPolicy}
                  submitButton={submit}
                  setIneligible={setIneligible}
                  linkClickHandler={linkClickHandler}
                />
              </FormContainer>
              <Illustration
                src={isMobile ? bg2 : bg1}
                alt="business illustration"
              />
            </FormSection>
          )}
          {registered && !postCampaign && (
            <FormSection>
              <VideoContainer
                play
                path={currentVideoUrl}
                loop
                mute
                thumbnail={isMobile ? bgMob : bg}
                isBackground
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  maxWidth: "100%",
                  maxHeight: "100%",
                  height: "100%",
                  width: "100%",
                }}
              />
              <FormContainer>
                <Heading>
                  Thanks for nominating your favorite small business. 
                </Heading>
                <Body>
                  You’ve entered them in a chance to win the open for small
                  business tech package! Plus, you’re registered for a chance to
                  win a $200 cash gift card. 
                </Body>
              </FormContainer>
            </FormSection>
          )}
          {postCampaign && (
            <FormSection>
              <VideoContainer
                play
                path={currentVideoUrl}
                loop
                mute
                thumbnail={isMobile ? bgMob : bg}
                isBackground
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  maxWidth: "100%",
                  maxHeight: "100%",
                  height: "100%",
                  width: "100%",
                }}
              />
              <FormContainer>
                <Heading>Sorry you missed it!</Heading>
                <Body>
                  The sweepstakes with Musiq Soulchild is over, but our
                  commitment to small businesses lives on. Be on the lookout for
                  future Elavon campaigns, and remember to always support your
                  local small businesses.
                </Body>
              </FormContainer>
            </FormSection>
          )}

          <AdSection>
            {isMobile ? (
              <h2>Elavon’s featured small businesses</h2>
            ) : (
              <h1>
                Elavon’s featured <br />
                small businesses
              </h1>
            )}
            <img src={img3} alt="small business illustration" />
          </AdSection>

          <ContentWrap>
            <Segment>
              <HeadlineBox>
                <h1>MINNEAPOLIS</h1>
              </HeadlineBox>
              <HeadlineBox></HeadlineBox>
            </Segment>

            <Segment>
              <ContentBox>
                <div>
                  <h2>El Itacate Restaurant</h2>
                  <p>
                    Offering some of the best tacos, caldos, and burritos in
                    Maplewood, MN.
                  </p>
                  <ButtonPrimary
                    onClick={() => {
                      openInNewTab("https://elitacaterestaurant.com/");
                      linkClickHandler(
                        "https://elitacaterestaurant.com/",
                        "El Itacate Restaurant",
                        "external"
                      );
                    }}
                  >
                    View business
                  </ButtonPrimary>
                  <div>
                    <Facebook
                      onClick={() =>
                        openInNewTab(
                          "https://www.facebook.com/profile.php?id=100077534464001"
                        )
                      }
                    />
                    <Google
                      as="a"
                      href="https://www.google.com/maps/place/El+Itacate+Mexican+Food+LLC/@44.9636948,-92.9904296,14z/data=!4m6!3m5!1s0x52b2d371e8136257:0x3a310e2e2ee2c332!8m2!3d44.9623692!4d-92.9853841!16s%2Fg%2F11tg8ngsm3?coh=164777&entry=tt&shorturl=1/"
                      onClick={() =>
                        openInNewTab(
                          "https://www.google.com/maps/place/El+Itacate+Mexican+Food+LLC/@44.9636948,-92.9904296,14z/data=!4m6!3m5!1s0x52b2d371e8136257:0x3a310e2e2ee2c332!8m2!3d44.9623692!4d-92.9853841!16s%2Fg%2F11tg8ngsm3?coh=164777&entry=tt&shorturl=1/"
                        )
                      }
                    />
                  </div>
                </div>
              </ContentBox>
              <ContentBox>
                <img src={b6} />
              </ContentBox>
            </Segment>

            <Segment last>
              <ContentBox>
                <img src={b5} />
              </ContentBox>
              <ContentBox>
                <div>
                  <h2>Reviving Roots Therapy & Wellness</h2>
                  <p>
                    Working toward a world where the Black community can embody
                    their own boundless power.{" "}
                  </p>
                  <ButtonPrimary
                    onClick={() => {
                      openInNewTab("https://reviving-roots.com/");
                      linkClickHandler(
                        "https://reviving-roots.com/",
                        "https://reviving-roots.com/",
                        "external"
                      );
                    }}
                  >
                    View business
                  </ButtonPrimary>
                  <div>
                    <Instagram
                      onClick={() =>
                        openInNewTab(
                          "https://www.instagram.com/revivingrootswellness/"
                        )
                      }
                    />
                    <Linkedin
                      onClick={() =>
                        openInNewTab(
                          "https://www.linkedin.com/company/reviving-roots/"
                        )
                      }
                      id="Linkedin"
                    />
                  </div>
                </div>
              </ContentBox>
            </Segment>
          </ContentWrap>

          <ContentWrap>
            <Segment>
              <HeadlineBox>
                <h1>ATLANTA</h1>
              </HeadlineBox>
              <HeadlineBox></HeadlineBox>
            </Segment>
            <Segment>
              <ContentBox>
                <div>
                  <h2>Paco Tacos ATL</h2>
                  <p>
                    Serving up a delicious array of Mexican Street eats with
                    style and grace.
                  </p>
                  <ButtonPrimary
                    onClick={() => {
                      openInNewTab("http://pacotacosatl.com/");
                      linkClickHandler(
                        "http://pacotacosatl.com/",
                        "http://pacotacosatl.com/",
                        "external"
                      );
                    }}
                  >
                    View business
                  </ButtonPrimary>
                  <div>
                    <Facebook
                      onClick={() =>
                        openInNewTab("https://www.facebook.com/Pacotacosatl/")
                      }
                    />
                    <Instagram
                      onClick={() =>
                        openInNewTab(
                          "https://www.instagram.com/pacotacosatl/?hl=en"
                        )
                      }
                    />
                  </div>
                </div>
              </ContentBox>
              <ContentBox>
                <img src={b1} />
              </ContentBox>
            </Segment>
            <Segment last>
              <ContentBox>
                <img src={b2} />
              </ContentBox>
              <ContentBox>
                <div>
                  <h2>PuffCuff</h2>
                  <p>
                    A tool that makes it easy to achieve stylish looks without
                    stressing curly hair or causing pain from pulling and
                    tension.
                  </p>
                  <ButtonPrimary
                    onClick={() => {
                      openInNewTab("https://thepuffcuff.com/");
                      linkClickHandler(
                        "https://thepuffcuff.com/",
                        "https://thepuffcuff.com/",
                        "external"
                      );
                    }}
                  >
                    View business
                  </ButtonPrimary>
                  <div>
                    <Facebook
                      onClick={() =>
                        openInNewTab(" https://www.facebook.com/ThePuffCuff")
                      }
                    />
                    <Instagram
                      onClick={() =>
                        openInNewTab("https://www.instagram.com/thepuffcuff/")
                      }
                    />
                  </div>
                </div>
              </ContentBox>
            </Segment>
          </ContentWrap>

          <ContentWrap>
            <Segment>
              <HeadlineBox>
                <h1>MIAMI</h1>
              </HeadlineBox>
              <HeadlineBox></HeadlineBox>
            </Segment>

            <Segment>
              <ContentBox>
                <div>
                  <h2>Los Paisanos Restaurant</h2>
                  <p>
                    Serving delicious Honduran and Central American food for
                    more than 20 years.
                  </p>
                  <ButtonPrimary
                    onClick={() => {
                      openInNewTab(
                        "https://www.google.com/maps/place/Los+Paisanos+Restaurant/@25.7734764,-80.2103851,17z/data=!3m1!4b1!4m6!3m5!1s0x88d9b69319cb2751:0x152117ebc34db1ef!8m2!3d25.7734764!4d-80.2081964!16s%2Fg%2F1tfd364m"
                      );
                      linkClickHandler(
                        "Los Paisanos Restaurant",
                        "Los Paisanos Restaurant",
                        "external"
                      );
                    }}
                  >
                    View business
                  </ButtonPrimary>
                  <div>
                    <Facebook
                      onClick={() =>
                        openInNewTab(
                          "https://www.facebook.com/people/Los-Paisanos-Restaurant/100075503546315/"
                        )
                      }
                    />
                    <Instagram
                      onClick={() =>
                        openInNewTab(
                          "https://www.instagram.com/lospaisanos824/?hl=en"
                        )
                      }
                    />
                  </div>
                </div>
              </ContentBox>
              <ContentBox>
                <img src={b4} />
              </ContentBox>
            </Segment>

            <Segment last>
              <ContentBox>
                <img src={b3} />
              </ContentBox>
              <ContentBox>
                <div>
                  <h2>Karnna Fashion Lab</h2>
                  <p>
                    Consulting services in the areas of design, product
                    development, and professional performance with a focus on
                    mentorship and community development.
                  </p>
                  <ButtonPrimary
                    onClick={() => {
                      openInNewTab("https://karnnafashionlab.com/");
                      linkClickHandler(
                        "https://karnnafashionlab.com/",
                        "https://karnnafashionlab.com/",
                        "external"
                      );
                    }}
                  >
                    View business
                  </ButtonPrimary>
                  <div>
                    <Facebook
                      onClick={() =>
                        openInNewTab(
                          "https://www.facebook.com/karina.gomezherrera.10/?fref=ts"
                        )
                      }
                    />
                    <Linkedin
                      onClick={() =>
                        openInNewTab(
                          "https://www.linkedin.com/in/karnna-fashion-b6255a104/"
                        )
                      }
                      id="Linkedin"
                    />
                  </div>
                </div>
              </ContentBox>
            </Segment>
          </ContentWrap>

          <BusinessSection />
        </Container>
        <Modal open={open} setOpen={setModalVisible}>
          <Media16By9>
            <MediaVideo
              src={video}
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </Media16By9>
        </Modal>
      </Layout>
    );
  }
);

const Title = styled.h1`
  font-family: "HelveticaNeueBd";
  font-size: 64px;
  line-height: 60px;
  text-transform: uppercase;
  color: #eaeceb;
  margin: 0 auto;
  width: 85%;
  text-shadow: 0px 4px 6px rgba(0, 0, 0, 0.5);

  @media (max-width: 950px) {
    font-size: ${(34 / 390) * 100}vw;
    line-height: ${(33 / 390) * 100}vw;
    width: 90%;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 245px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  @media (max-width: 950px) {
    padding-top: 20px;
    align-items: flex-start;
    height: 410px;
  }
`;

const Container = styled(MaxWidthContainer)`
  display: flex;
  flex: 1;
  gap: 0;
  background-color: #fff;
  flex-direction: column;
  padding: 0;
  position: relative;
`;

const VideoOverlay = styled.div`
  display: flex;
  width: 100%;
  padding-top: 59%;
  position: absolute;
  z-index: 8;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0) 100%
  );
`;
const VideoContainer = styled(VideoPlayer)`
  video {
    object-fit: cover;
    object-position: top left;
  }
  @media (max-width: 950px) {
    video {
      object-position: top left;
    }
  }
  @media (max-width: 550px) {
    video {
      object-fit: contain;
      //    object-position: top center;
    }
  }
`;
const Illustration = styled.img`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
  width: 97%;
  @media (max-width: 950px) {
  }
`;

const FormSection = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  background-color: #001E79;

  /* img {
    width: 100%;
    object-fit: cover;
  }
  background-image: url(${({ background }) => background});
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat; */
`;

const Section = styled.section`
  display: flex;
  flex: 1;
  height: 40vh;
  margin-bottom: 60px;

  /* ${(props) => props.form && "background-color: #f2f2f2; margin-bottom:0"} */

  ${(props) => props.business && "flex-direction: column; margin-bottom:0"}

    @media (max-width: 950px) {
    flex-direction: column;
    margin: 0;
  }

  @media (max-width: 950px) {
    ${(props) => props.form && "flex-direction: column;"}
  }
`;

const ArtistSection = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #f2f2f2;
  @media (max-width: 950px) {
    flex-direction: column-reverse;
  }
`;

const ArtistCTA = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  margin: auto;
  padding-right: 15%;
  button {
    margin: 20px auto 0 0;
    width: 220px;
  }
  p {
    color: #000000;
  }
  @media (max-width: 950px) {
    width: 70%;
    padding: unset;
    margin: 50px auto 25px;
    button {
      width: 100%;
    }
  }
`;
const ArtistImage = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;
  img {
    width: 70%;
    margin: 50px 0 0 auto;
  }
  @media (max-width: 950px) {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    img {
      width: 80%;
      margin: 50px auto 0 auto;
    }
  }
`;

const AdSection = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 30px auto;
  justify-content: flex-start;
  align-items: center;
  padding-left: 150px;
  gap: 45px;
  img {
    width: 60%;
    object-fit: cover;
    margin-right: -10vw;
  }
  @media (max-width: 950px) {
    margin: 35px auto;
    padding-right: 20px;
    padding-left: 20px;
    max-width: 100%;
    flex-direction: column;
    gap: 20px;
    img {
      width: 100%;
      margin-right: 0;
    }
    h2 {
      text-align: center;
    }
  }
  h1,
  h2 {
    color: #001e79;
    font-family: "HelveticaNeueMed";
  }
`;
const ContainerPadding = styled.div`
  padding: 0 4vw;

  @media (max-width: 950px) {
    padding: 20px 30px 0 30px;
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  margin: 245px auto 250px;
  padding: 70px 100px;
  border-radius: 20px;
  border: 1px solid #cccccc;
  background-color: #ffffff;
  height: 70%;
  z-index: 20;

  @media (max-width: 950px) {
    padding: 30px;
    width: 90%;
    margin: 270px auto 250px;
  }
  @media (min-width: 480px) and (max-width: 950px) {
    margin: 410px auto 250px;
  }
`;

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 50px;
`;
const Segment = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  @media (max-width: 950px) {
    flex-direction: column-reverse;
    ${(props) => props.last && "flex-direction: column;"}
  }
`;
const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: center;
  align-items: flex-start;
  background-color: #f2f2f2;
  button {
    margin: 10px auto 20px 0;
  }
  div {
    & > svg {
      margin-right: 10px;
      cursor: pointer;
    }
    svg#Linkedin {
      margin: -3px 0px 3px 5px;
      cursor: pointer;
    }
  }
  & > div {
    width: 60%;
    margin-left: 150px;
  }

  img {
    width: 100%;
    object-fit: cover;
  }
  @media (min-width: 950px) and (max-width: 1180px) {
    align-items: center;
    & > div {
      margin-left: initial;
    }
  }
  @media (max-width: 950px) {
    width: 100%;
    align-items: center;
    aspect-ratio: 1.2;
    div {
      margin-left: unset;
    }
    img {
      aspect-ratio: 1/1;
    }
  }
`;
const HeadlineBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: flex-end;
  align-items: flex-start;

  h1 {
    margin: 0;
    font-size: 2.4rem;
    line-height: 1.9rem;
    font-family: "HelveticaNeueBd";
    margin-left: 150px;
  }
  @media (max-width: 950px) {
    width: 100%;
    align-items: flex-start;
    h1 {
      margin-left: 10%;
    }
  }
`;

const ScrollContainer = styled.div`
  background-color: #2075c3;
  padding: 5px 15px;
  border-radius: 7px;
  color: white;
  text-align: center;
  width: 45%;
  margin: 20px 0;
  font-size: max(0.9rem, 1vw);
  cursor: pointer;

  @media (max-width: 950px) {
    width: 100%;
  }
`;

const LogoBox = styled.div`
  padding: 50px 50px 0 50px;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 25px;

  @media (max-width: 950px) {
    margin: 0 auto;
    padding: 20px;
    justify-content: center;
  }
`;

const Link = styled.a`
  /* width: 100%;
  max-width: 80px; */

  /* @media only screen and ${(props) => props.theme.maxMedia.medium} {
    max-width: 120px;
  } */
`;

const BrandLink = styled.a`
  color: #2f75c3;
  text-decoration: none;
`;

const Logo = styled.img`
  width: 50%;
  display: flex;

  @media (max-width: 950px) {
    ${(props) => props.header && "width: 70%; margin: 0 auto"}
  }

  ${(props) => props.cta && "width: 80%"}
  ${(props) => props.icon && "width: 30px; height: 30px; margin-right: 10px;"}
  ${(props) => props.ctaArtist && "width: 70%; margin-bottom: 30px"}
`;

const Image = styled.img`
  width: 100%;
  object-fit: cover;
`;
const LinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 30px;
`;

const ButtonCTA = styled(ButtonPrimary)`
  border: 2px solid white;
  width: 100%;
  margin: 0 auto;
  padding: 3%;
  font-size: max(1.1rem, 1.4vw);

  & > a {
    color: white;
    text-decoration: none;
  }

  & > a :active {
    color: red;
    text-decoration: none;
  }

  ${(props) =>
    props.shareLink &&
    "width: 50%; font-size: 1rem; padding: unset; margin: unset"}
`;

// const BrandContent = styled.div`
//   width: 100%;
//   /* max-width: 450px; */
//   margin: 0 auto;
//   padding: 40px 40px;
//   position: absolute;
//   bottom: 0;
// `;

const MainHeading = styled.h1`
  margin-top: 35px;
  border-top: 1px solid white;
  font-size: 1.5rem;
  padding: 25px 6vw 10px 0;
  & > span {
    display: block;
    font-size: 20px;
  }
`;

const Heading = styled.h2`
  font-size: max(1.5vw, 1.3rem);

  span {
    position: relative;
    top: -10px;
    font-size: 0.8rem;
  }
`;
const SubHeading = styled.h3``;
const Body = styled.p`
  font-size: max(0.5vw, 0.8rem);
  color: #0c2074;

  span {
    position: relative;
    top: -4px;
    font-size: 0.5rem;
  }

  ${(props) => props.note && "font-size: max(0.3vw, 0.6rem);"}
`;
const HeadingPostCampaign = styled(Heading)`
  margin-top: 10px;
  font-size: max(1.7vw, 1.5rem);

  @media (max-width: 950px) {
    margin-top: 0;
  }
`;
const BodyPostCampaign = styled(Body)``;

const Icon = styled(FontAwesomeIcon)`
  margin: 0 0 -2px 10px;
  border: 2px solid #2f75c3;
  border-radius: 50px;
`;

const CTAHeading = styled.h2`
  font-size: max(2.3rem, 2vw);
  padding-right: 20%;
`;

const FormLegal = styled.div`
  font-size: 12px;
  margin: 0;
  line-height: 1.6;
  order: 99;
  color: white;

  a,
  a:visited {
    color: white;
  }
`;

const topBorder = `
  border-top: 1px solid #707070;
  padding-top: 24px;
  margin-top: 24px;
`;

const PoweredBy = styled.div`
  ${topBorder}
`;

const BrandSecondaryCTA = styled.div`
  ${topBorder}
`;

const ArtistContent = styled.div`
  display: flex;
  flex: 1 0 50%;
  /* height: 400px; */
  /* background-image: url(${(props) => props.desktop});
  background-position: center;
  background-size: cover; */
`;

const BrandContent = styled.div`
  display: flex;
  flex: 1;
  /* height: 400px; */
  /* background-image: url(${(props) => props.desktop});
  background-position: center;
  background-size: cover;
  padding: 50%; */
`;

const ArtistContentBottom = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: 20px;

  @media (max-width: 950px) {
    flex-direction: column;
  }
`;

const BrandImage = styled.div`
  flex: 1;
  background-image: url(${(props) => props.mobile});
  background-position: bottom;
  background-size: cover;
  padding-top: 56.25%;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    background-image: url(${(props) => props.desktop});
    background-position: center;
  }
`;

const ArtistMobileImage = styled.div`
 display: none;
  background-image: url(${(props) => props.desktop});
  background-position: center;
  flex: 1;
  background-size: cover;
  padding-top: 76.25%;

@media (max-width: 950px) {
  display: block;
  flex: 1;
  /* background-image: url(${(props) => props.mobile}); */
  /* background-position: bottom; */
  background-size: cover;
  padding-top: 76.25%;


}
`;

const ArtistCTAContainer = styled.div`
  background-image: url(${(props) => props.desktop});
  background-position: center;
  flex: 1;
  background-size: cover;
  padding-top: 40%;
  position: relative;

@media (max-width: 950px) {
  flex: 1;
  /* background-image: url(${(props) => props.mobile}); */
  /* background-position: bottom; */
  background-size: cover;
  padding-top: 86.25%;
}
`;

const BrandCTAContainer = styled.div`
  background-image: url(${(props) => props.desktop});
  background-position: center;
  flex: 1;
  background-size: cover;
  padding-top: 40%;
  position: relative;

@media (max-width: 950px) {
  flex: 1;
  /* background-image: url(${(props) => props.mobile}); */
  /* background-position: bottom; */
  background-size: cover;
  padding-top: 86.25%;
}
`;

const HeroVideoMobile = styled.div`
  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    display: none;
  }
`;

const HeroVideoDesktop = styled.div`
  display: none;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    display: flex;
    width: 66%;
  }
`;

const MusicVideo = styled.div`
  position: relative;
  overflow: hidden;
  cursor: pointer;

  & > img {
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    aspect-ratio: 16/9;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &:hover > img {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
  }

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    flex: 1 1 0%;
    padding: 0;
  }
`;

const MobileImage = styled.img`
  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    display: none;
  }
`;

const DesktopImage = styled.img`
  display: none;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    display: block;
  }
`;

const BrandCTA = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  aspect-ratio: 16/9;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  & > img {
    position: absolute;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    aspect-ratio: 16/9;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &:hover > img {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
  }

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    & > img {
      width: 100%;
    }
  }
`;

const BrandCTAHeading = styled.h3`
  color: #ffffff;
  padding: 0 20px;
  margin: 0 0 40px;
  z-index: 1;
`;

const Label = styled.div`
  background-color: rgba(0, 9, 91, 0.9);
  color: #ffffff;
  padding: 10px 20px;
  width: 100%;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  text-decoration: none;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    font-size: 12px;
  }
`;
