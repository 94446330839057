import styled from "styled-components";
import { FieldMessage } from "Components";

export const Input = ({
  onChange,
  value = "",
  placeholder,
  order,
  error,
  success,
  disabled,
  placeholderStyle,
  ...props
}) => {
  const handleChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <TextContainer error={error} disabled={disabled}>
      <Field
        onChange={handleChange}
        value={value}
        order={order}
        error={error}
        disabled={disabled}
        success={success}
        {...props}
      />
      <Placeholder error={error} visible={value} htmlFor={props.name}>
        <span>{!value && !success && placeholder}</span>
        {success && (
          <FieldMessage
            content={error.message}
            type={error.status ? "error" : "success"}
            style={{
              marginTop: "0",
              marginLeft: "8px",
            }}
          />
        )}
      </Placeholder>
    </TextContainer>
  );
};

const primaryColor = "#00095b";
const inputPadding = "10px 20px";
const borderRadius = "7px";
const successBorderColor = "#4580dd";
const errorBackground = "#fff";
const errorBorderColor = "#E45C52";
const errorFieldColor = "#6d2621";

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${(props) => (props.disabled ? "#A6AEB2" : "#ffffff")};
  ${(props) => props.error && `background-color: ${errorBackground};`}
  border-radius: ${borderRadius};
  position: relative;
`;

const Placeholder = styled.label`
  display: flex;
  flex-direction: row;
  color: ${(props) => (props.error ? `${errorFieldColor}` : "#3B3B3B")};
  font-size: 0.8rem;
  position: absolute;
  padding: 5px 2px 5px 20px;
  /* transition: all 0.2s ease-in-out; */

  ${(props) =>
    props.visible &&
    `
    top: -24px;
    right: 0;
    font-size: 11px;
  `}
`;

const Field = styled.input`
  background-color: transparent;
  border: 1px solid #cccccc;
  border-radius: ${borderRadius};
  outline: 0;
  padding: ${inputPadding};
  position: relative;
  width: 100%;
  -webkit-appearance: none;
  color: ${primaryColor};
  z-index: 1;

  &:focus {
    border: 1px solid #4580dd;
  }

  &:focus + label {
    top: -20px;
    right: 0;
    padding: 0;
    font-size: 12px;
    span {
      display: none;
    }
  }

  &:disabled {
    border: 1px solid #626466;
  }

  ${(props) =>
    props.success &&
    `
    border: 1px solid ${successBorderColor};

    &:focus {
      border: 1px solid ${successBorderColor};
    }
  `}

  ${(props) =>
    props.error &&
    `
    border: 2px solid ${errorBorderColor};

    &:focus {
      border: 2px solid ${errorBorderColor};
    }
  `};

${(props) =>
  props.order === 3 &&
  "background-color: white; z-index: 0; width: 100%; align-self: flex-end;"}

/* ${(props) =>
  props.order === 5 &&
  "background-color: red; z-index: 1; width: 100%; align-self: flex-start"} */
`;
