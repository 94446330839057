import * as React from "react";
import { SVGProps } from "react";
export const Google = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={36}
    height={36}
    viewBox="0 0 53 53"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M38.0206 30.4167C37.3846 32.6293 36.124 34.6116 34.39 36.126C32.6559 37.6404 30.522 38.6227 28.2439 38.9551C26.4624 39.2135 24.6471 39.1093 22.9069 38.6488C21.1667 38.1882 19.5375 37.3807 18.1171 36.2749C16.6967 35.169 15.5144 33.7875 14.6412 32.2133C13.768 30.6392 13.2219 28.9048 13.0356 27.1143C12.8914 25.3168 13.1216 23.509 13.7118 21.805C14.302 20.101 15.2393 18.5381 16.4645 17.2149C17.6896 15.8917 19.176 14.8371 20.8296 14.1178C22.4832 13.3985 24.268 13.0301 26.0713 13.0358C27.761 13.0382 29.4348 13.3627 31.0031 13.9918C31.2501 14.1027 31.5295 14.1173 31.7867 14.0329C32.0439 13.9485 32.2603 13.7712 32.3936 13.5355L35.5221 7.77813C35.5895 7.64552 35.63 7.50092 35.6413 7.35262C35.6526 7.20432 35.6345 7.05524 35.5881 6.91394C35.5417 6.77264 35.4678 6.6419 35.3707 6.52922C35.2737 6.41654 35.1553 6.32413 35.0224 6.25731C31.663 4.73629 27.9716 4.09543 24.2961 4.3951C20.6205 4.69476 17.0817 5.92507 14.013 7.97017C10.9443 10.0153 8.4466 12.8078 6.75525 16.0848C5.06389 19.3618 4.23451 23.0155 4.34521 26.7015C4.55931 32.1763 6.80976 37.3739 10.6557 41.2761C14.5017 45.1783 19.666 47.504 25.1371 47.7976C30.8682 48.0517 36.4673 46.0288 40.7126 42.1702C44.9578 38.3116 47.5047 32.9306 47.7974 27.2012C47.7974 26.7667 47.7974 24.2682 47.7974 22.856C47.7918 22.5697 47.6756 22.2966 47.473 22.094C47.2705 21.8915 46.9974 21.7753 46.7111 21.7697H27.1576C26.8695 21.7697 26.5932 21.8842 26.3895 22.0879C26.1857 22.2916 26.0713 22.5679 26.0713 22.856V29.3739C26.0713 29.662 26.1857 29.9383 26.3895 30.142C26.5932 30.3457 26.8695 30.4602 27.1576 30.4602H38.0206"
      fill="#0C2074"
    />
  </svg>
);
