import { Input, FieldMessage } from "Components";
import { getFormattedPhoneNumber } from "Components/Form/InputMasks";

export const PhoneField = ({
  onChange,
  value,
  field,
  error = {},
  ...props
}) => {
  const handleText = (value) => {
    const formattedNumber = value.length ? getFormattedPhoneNumber(value) : "";
    onChange({ [field.name]: formattedNumber });
  };

  return (
    <>
      <Input
        type="tel"
        placeholder={field.placeholder ?? ""}
        maxLength={16}
        onChange={(value) => handleText(value)}
        value={value}
        error={!!error.status}
        success={!error.status && value}
        name={field.name}
        {...props}
      />
      {error.status && (
        <FieldMessage
          active={value || !!error.status}
          content={error.message}
          type={error.status ? "error" : "success"}
        />
      )}
    </>
  );
};
