import * as React from "react";
import { SVGProps } from "react";
export const Linkedin = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={30}
    height={30}
    viewBox="0 0 51 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M46.7939 0H4.28647C2.25367 0 0.604004 1.61086 0.604004 3.59448V46.2855C0.604004 48.2719 2.25367 49.8848 4.28647 49.8848H46.7939C48.8281 49.8848 50.4888 48.2719 50.4888 46.2855V3.59448C50.4888 1.61086 48.8281 0 46.7939 0ZM15.399 42.5088H7.99665V18.7033H15.399V42.5088ZM11.6978 15.4484C9.32622 15.4484 7.40912 13.5264 7.40912 11.1569C7.40912 8.79081 9.32622 6.86886 11.6978 6.86886C14.0639 6.86886 15.9858 8.79081 15.9858 11.1569C15.9858 13.5264 14.0639 15.4484 11.6978 15.4484ZM43.11 42.5088H35.7194V30.9327C35.7194 28.1711 35.6654 24.6202 31.8735 24.6202C28.0226 24.6202 27.4358 27.6279 27.4358 30.7325V42.5095H20.0397V18.7033H27.1379V21.9542H27.239C28.227 20.0835 30.6409 18.1096 34.2409 18.1096C41.7278 18.1096 43.1107 23.0385 43.1107 29.4507L43.11 42.5088Z"
      fill="#0C2074"
    />
  </svg>
);
