import React, { useContext, useState, useRef } from "react";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { TimeContext, EventContext, AnalyticsContext } from "context";
import { MaxWidthContainer } from "./MaxWidthContainer";
import { useMediaQuery } from "hooks/useMediaQuery";

export const Footer = ({
  showFooter,
  registration,
  registered,
  className,
  voting,
  postCampaign,
  preCampaign,
}) => {
  const { time } = useContext(TimeContext);
  const { event } = useContext(EventContext);
  const { logClick } = useContext(AnalyticsContext);
  const isMobile = useMediaQuery();

  const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
  const storageDirectory = event.meta.storageDirectory;

  const {
    // brand: brandLogo,
    // event: eventLogo,
    secondary: secondaryLogo,
  } = event.logos;

  let secondaryLogoImg = `${api}/o/${storageDirectory}%2F${secondaryLogo?.image}?alt=media`;

  const footerMenu = false;
  const showLogo = true;

  const {
    maxPolicy,
    brandDisclaimer,
    sweepsDisclaimer,
    secondarySweepsDisclaimer,
    grandPrizeDisclaimer,
    brandImage,
    brandImageMob,
  } = event.footer;
  console.log("sec", secondarySweepsDisclaimer);

  let brandImgDesktop = `${api}/o/${storageDirectory}%2F${brandImage}?alt=media`;
  let brandImgMobile = `${api}/o/${storageDirectory}%2F${brandImageMob}?alt=media`;

  const linkClickHandler = (url, label, category) => {
    logClick({ label, url }, category);
  };

  const footerMenuClickHandler = (link) => {
    logClick({
      label: "Footer Menu Link",
      url: link,
    });
  };

  return (
    <>
      {showFooter && (
        <FooterWrapper as="footer" className={className}>
          <FooterContainer preCampaign={preCampaign}>
            <Content>
              {!preCampaign && (
                <>
                  <SweepsDisclaimer>
                    {/* {sweepsDisclaimer && ReactHtmlParser(sweepsDisclaimer)} */}
                    NO PURCHASE NECESSARY. PURCHASES DO NOT INCREASE ODDS OF
                    WINNING. Odds depend on the number of eligible entries
                    received. VOID WHERE PROHIBITED. Open to legal residents of
                    the contiguous United States and the District of Columbia
                    (excluding residents of Alaska, Hawaii, Puerto Rico, Guam,
                    the Virgin Islands, and other US Overseas Territories) who
                    are 18 years of age or older (19 years or older if a
                    resident of NE or AL, 21 years or older if resident of MS).
                    ARV of all prizes: $1,000.
                    <br />
                    <br />
                    Limit one (1) entry per person, per Entry period. For Rules,
                    see{" "}
                    <a href="rules" target="_blank">
                      https://elavonforsmallbusiness.com/rules
                    </a>
                    . Sponsor: Music Audience Exchange, Inc., 2595 Dallas
                    Parkway, Suite 440, Frisco, TX 75034. Ends December 15,
                    2023.
                    <br />
                    <br />
                  </SweepsDisclaimer>
                </>
              )}
              <MaxPolicy
                onClick={() =>
                  linkClickHandler(
                    "https://www.max.live/",
                    "MAX homepage/privacy policy",
                    "footer"
                  )
                }
              >
                {ReactHtmlParser(maxPolicy)}{" "}
                <CookieButton
                  onClick={() => {
                    if (window.Cookiebot) {
                      window.Cookiebot.renew();
                    }
                  }}
                >
                  Click here to set Cookie Preferences.
                </CookieButton>
              </MaxPolicy>
            </Content>
          </FooterContainer>
        </FooterWrapper>
      )}
    </>
  );
};

const FooterWrapper = styled.footer`
  display: flex;
  flex-direction: row;
  font-size: 10px;
  /* padding: 0 15px; */
  /* z-index: 1; */
  color: white;
`;

const FooterContainer = styled(MaxWidthContainer)`
  padding: 0;
  flex-direction: row;
  justify-content: space-between;
  background-color: #001e79;

  @media (max-width: 950px) {
    flex-direction: column;
    justify-content: center;
    flex: 1;
    position: relative;
  }
`;

const Content = styled.div`
  padding: 60px 100px;

  @media (max-width: 950px) {
    padding: 20px 30px;
  }
`;

const Image = styled.img`
  width: 100%;
  object-fit: cover;
`;

const Link = styled.a`
  margin: 15px;
  align-self: center;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    margin: 0 0 0 50px;
    align-self: start;
  }
`;

const Logo = styled.img`
  width: 100%;
  max-width: 234px;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    width: unset;
    margin-top: 10px;
  }
`;

const SweepsDisclaimer = styled.div`
  margin-top: 0;

  a {
    color: white;
  }
`;

const MaxPolicy = styled.p`
  margin-top: 0;

  & > a {
    color: white;
  }

  @media (max-width: 950px) {
    ${(props) =>
      props.preCampaign && "text-align: center;"}/* text-align: center; */
  }
`;

const BrandDisclaimer = styled.div``;

const FooterMenu = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;

  li {
    padding: 3px 8px;
  }

  a,
  a:visited {
    color: white;
  }

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    flex-direction: row;
  }
`;
const CookieButton = styled.button`
  border: none;
  background-color: transparent;
  color: #ffffff;
  display: inline;
  font-size: 10px;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
`;
