import React, { useState, useEffect, Fragment } from "react";
import styled from "styled-components";
import { Footnote, Icon, TextField } from "notes";

const IconContainer = styled.div`
  color: ${(props) => props.theme.input.placeholder.color};
  position: absolute;
  top: 10px;
  left: 10px;
  width: 16px;
  height: 16px;
`;

export const Caret = styled(Icon)`
  position: absolute;
  right: 11px;
  top: 11px;
  width: 16px;
  height: 16px;
  z-index: 1;
  path {
    fill: white;
  }
`;

const SelectField = styled(TextField).attrs(() => ({ as: "div" }))`
  background-color: transparent;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: ${(props) => (props.icon ? "35px" : "12px")};
  padding-right: 30px;
  position: relative;
  z-index: 1;
  ${(props) =>
    props.open &&
    `
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: none;
    box-shadow: 0 -1px 8px 0 rgba(0, 0, 0, 0.24);
    z-index: 4;
    &::after {
      content: "";
      background-color: white;
      position: absolute;
      top: calc(100%);
      left: 0;
      width: 100%;
      height: 1px;
      z-index: 5;
    }
  `};
  &:focus {
    border-color: white;
    border-bottom-color: white;
    box-shadow: 0 -1px 8px 0 rgba(0, 0, 0, 0.24);
  }
  ${(props) =>
    props.disabled &&
    `background-color: ${props.theme.input.disabled.backgroundColor};
    border-color: ${props.theme.input.disabled.borderColor};
    pointer-events: none;
    ${IconContainer} {
      color: ${props.theme.input.disabled.placeholder.color};
    }
    `};
  ${(props) =>
    props.error &&
    `padding-top: 7px;
    padding-bottom: 7px;
    padding-left: ${props.icon ? "34px" : "11px"};
    padding-right: 29px;
    background-color: ${props.theme.input.error.backgroundColor};
    ${IconContainer} {
      top: 9px;
      left: 9px;
    }
    ${Caret} {
      top: 10px;
      right: 10px;
    }
    `};
`;

const Dropdown = styled.div`
  position: absolute;
  display: block;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border-width: 1px;
  border-style: solid;
  border-color: white;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top: none;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.24);
  max-height: 224px;
  opacity: 0;
  overflow-x: hidden;
  overflow-y: auto;
  visibility: hidden;
  pointer-events: none;
  z-index: 3;
  ${(props) =>
    props.open &&
    `opacity: 1;
    visibility: visible;
    pointer-events: all;`}
`;

const Item = styled.div`
  align-items: flex-start;
  background-color: ${(props) =>
    props.selected ? "#007079" : "#FFFFFF"};
  border-bottom-style: solid;
  border-bottom-color: white;
  border-bottom-width: 1px;
  box-sizing: border-box;
  color: ${(props) =>
    props.selected ? "#FFF" : "#007079"};
  cursor: pointer;
  display: flex;
  ${(props) => props.theme.fonts.default}
  flex-flow: column wrap;
  font-weight: ${(props) => (props.selected ? 600 : 400)};
  justify-content: ${(props) => (props.twoLine ? "flex-start" : "center")};
  line-height: 22px;
  ${(props) => props.paddingChange && `min-height: 71px;`};
  position: relative;
  padding: ${(props) =>
    props.paddingChange ? "15px 12px 15px 67px" : "17px 12px"};
  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.selectItemHover};
  }
  &:last-of-type {
    border-bottom: 0;
  }
`;

const SubTitle = styled(Footnote)`
  display: block;
  margin-top: 4px;
  width: 100%;
`;

const Selected = styled.span`
  align-items: center;
  color: white;
  display: ${(props) => (props.withImage ? "flex" : "block")};
  ${(props) => props.theme.fonts.default};
  font-size: ${(props) => (props.placeholderValue ? "12px" : "17px")};
  font-weight: ${(props) => (props.placeholderValue ? 300 : 600)};
  justify-content: ${(props) =>
    props.withImage ? "flex-start" : "space-between"};
  line-height: 22px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${(props) =>
    props.disabled &&
    `color: ${
      props.placeholderValue
        ? props.theme.input.disabled.placeholder.color
        : props.theme.input.disabled.color
    };`}
`;

export const Select = ({
  options = [],
  disabled,
  icon,
  placeholder,
  selected = {},
  country = false,
  onChange,
  onOpen,
  ...props
}) => {
  const [openState, setOpenState] = useState(false);
  const [fieldRef, setFieldRef] = useState(false);

  const handleOpenState = (val) => {
    setOpenState(val);
    !!onOpen && onOpen(val);
  };

  const handleClickOutside = (evt) => {
    if (!fieldRef.contains(evt.target)) {
      handleOpenState(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  });

  return (
    <FieldWrapper country={country} ref={(component) => setFieldRef(component)}>
      <SelectField
        open={openState}
        icon={!!icon}
        disabled={disabled}
        {...props}
        onClick={() => handleOpenState(!openState)}
      >
        {!!icon && <IconContainer>{icon}</IconContainer>}
        <Selected
          disabled={disabled}
          withImage={!!selected.image}
          placeholderValue={selected.id ? false : true}
        >
          {!!selected.image ? (
            <Fragment>
              <SelectedImageMask image={selected.image} />
              {selected.value.toUpperCase()}
            </Fragment>
          ) : (
            <Fragment>{selected.id ? selected.text : placeholder}</Fragment>
          )}
        </Selected>
        <Caret indicator name="Dropdown" disabled={disabled} />
      </SelectField>
      <Dropdown open={openState}>
        {options.map((option) => {
          const { text, image, id, subtext } = option;
          return (
            <Item
              key={id}
              selected={id === selected.id}
              onClick={() => {
                onChange(option);
                handleOpenState(false);
              }}
              paddingChange={!!image}
              twoLine={!!subtext}
            >
              {!!subtext ? (
                <Fragment>
                  {!!image && (
                    <LabelImageContainer>
                      <LabelImage src={image} />
                    </LabelImageContainer>
                  )}
                  {text}
                  <SubTitle>{subtext}</SubTitle>
                </Fragment>
              ) : (
                <Fragment>
                  {!!image && (
                    <LabelImageContainer>
                      <LabelImage src={image} />
                    </LabelImageContainer>
                  )}
                  {text}
                </Fragment>
              )}
            </Item>
          );
        })}
      </Dropdown>
    </FieldWrapper>
  );
};

const SelectedImageMask = styled.div`
  background: url(${(props) => props.image});
  background-size: cover;
  background-position: center center;
  align-items: center;
  overflow: hidden;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  position: relative;
  width: 16px;
  height: 16px;
  margin-right: 12px;
`;

const SelectedImage = styled.img`
  display: block;
  height: auto;
  width: 32px;
  position: relative;
`;

const LabelImageContainer = styled.div`
  align-items: center;
  display: inline-flex;
  justify-content: center;
  /* margin-right: 12px; */
  border-radius: 50%;
  overflow: hidden;
  height: 40px;
  width: 40px;
  position: absolute;
  left: 12px;
`;

const LabelImage = styled.img`
  display: block;
  height: auto;
  width: 76px;
  position: relative;
`;

export const FieldWrapper = styled.div`
  background: transparent;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  ${(props) => !props.country && "position: relative"};
  label > & {
    margin-top: 9px;
  }
  /* width: fit-content; */
`;
