import React, { useState, useReducer, useContext, useEffect } from "react";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { reducer } from "Modules";
import { EventContext, UserContext, AnalyticsContext } from "context";
import { ButtonPrimary } from "Components/Buttons";
import { handleErrors, Field } from "Components/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMediaQuery } from "hooks/useMediaQuery";
import { ReactComponent as ChevronIcon } from "svg/chevron-right-circle.svg";

export const RegistrationForm = ({
  formPart,
  formHeading,
  formLegal,
  brandLegal,
  timeStamp,
  submitButton,
  setIneligible,
  linkClickHandler,
}) => {
  const { event } = useContext(EventContext);
  const { writeToUserSubmissionDoc, saveEntry } = useContext(UserContext);
  const { logLeadSubmission } = useContext(AnalyticsContext);

  const [registration, setRegistration] = useReducer(reducer, {});
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const fields = event.register.fields;
  const isMobile = useMediaQuery();
  console.log(registration);

  const handleChange = (value, name) => {
    setRegistration(value);
    delete errors[name];
    setErrors(errors);
  };

  useEffect(() => {
    setSubmitting(false);
  }, [submitButton]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errorObject = handleErrors(fields, registration, formPart);

    if (!errorObject) {
      setSubmitting(true);

      registration.formPart = formPart;
      // registration.timeStamp = timeStamp
      await writeToUserSubmissionDoc(registration);
      await saveEntry();
      logLeadSubmission({ formPart, registration });
    } else {
      setSubmitting(false);
      setErrors(errorObject);
    }
  };

  return (
    <>
      {formHeading && <FormTitle>{ReactHtmlParser(formHeading)}</FormTitle>}
      <SubHeading>
        By nominating, you will be entered for a chance to win a $200 cash gift
        card.
      </SubHeading>
      <Body>You may nominate any small business of your choice.</Body>
      <p style={{ color: "red", fontSize: "max(0.5vw, 0.8rem)" }}>
        *Required fields
      </p>

      {fields && (
        <RegForm onSubmit={handleSubmit}>
          <Form>
            {fields
              .sort((a, b) => a.order - b.order)
              .filter((field) => {
                return field.formPart === formPart;
              })
              .map((field, index) => {
                let mobileSize = "100%";
                let desktopSize = "94.5%";
                // let isHidden = field.order === 6 && !registration.BUSINESSTYPE;
                let isHidden = false;
                if (field.span === "half") desktopSize = "calc(46.5% - 4px)";

                if (
                  field.span !== "full" &&
                  (field.spanMobile === "half" || !field.spanMobile)
                )
                  mobileSize = "calc(50% - 4px)";

                if (
                  field.name === "BUSINESS" &&
                  registration.BUSINESSTYPE &&
                  isMobile
                ) {
                  const fieldObject = fields?.find(
                    (field) => field.name === "BUSINESSTYPE"
                  );
                  const selectedOption = fieldObject?.options?.find(
                    (option) => option.id == registration.BUSINESSTYPE
                  );
                  field.label = selectedOption.text;
                }
                return (
                  <FieldContainer
                    sizeDesktop={desktopSize}
                    sizeMobile={mobileSize}
                    fullSize={field.span === "full"}
                    key={index}
                    order={field.order}
                    isHidden={isHidden}
                  >
                    {field.type !== "optIn" && (
                      <Label order={field.order}>
                        {/* {isMobile && field.order === 6
                          ? printLabel : ReactHtmlParser(field.label)} */}
                        {ReactHtmlParser(field.label)}
                      </Label>
                    )}
                    <Field
                      field={field}
                      order={field.order}
                      value={registration[field.name]}
                      onChange={(value) => handleChange(value, field.name)}
                      error={{
                        status: errors[field.name],
                        message: errors[field.name]?.message,
                      }}
                    />
                  </FieldContainer>
                );
              })}

            {formLegal && (
              <>
                <FormLegal>{ReactHtmlParser(brandLegal)}</FormLegal>
                <FormLegal
                  onClick={() =>
                    linkClickHandler(
                      "https://elavonforsmallbusiness.com/rules/",
                      "Sweepstakes Rules",
                      "internal"
                    )
                  }
                >
                  <a
                    href="rules"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="uppercase"
                  >
                    Sweepstakes Rules
                  </a>
                  <br />
                </FormLegal>
              </>
            )}
          </Form>

          {submitting ? (
            <RegisterButton>Loading</RegisterButton>
          ) : (
            <RegisterButton
              type="submit"
              onClick={() =>
                linkClickHandler(
                  "Form Registration Submit",
                  "Home - Enter and Nominate",
                  "internal"
                )
              }
            >
              {submitButton ? submitButton : "Submit"}
            </RegisterButton>
          )}
        </RegForm>
      )}
    </>
  );
};

const Icon = styled(ChevronIcon)`
  color: white;
  fill: white;
  margin: 0 0 -6px 2px;
`;

const FormTitle = styled.h3`
  font-weight: 400;
  margin: 12px 0;
  font-size: max(1.4rem, 1.2vw);
  span {
    font-size: 1rem;
    position: relative;
    top: -6px;
  }
  @media (min-width: 951px) {
    padding-right: 40px;
  }
`;

const Form = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 15px;
  row-gap: 24px;
  margin: 12px 0 20px;
  width: 100%;
`;

const RegForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: start;
  z-index: 15;
`;

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 ${(props) => props.sizeMobile};
  ${(props) => props.order && `order: ${props.order};`}
  width: auto;

  @media (min-width: 1360px) {
   // flex: 0 1 ${(props) => props.sizeDesktop};
    ${(props) => props.order === 3 && `flex: 0 1 78%;`}
    ${(props) => props.order === 2 && `flex: 0 1 20%;`}
  }

  /* @media (min-width: 950px) and (max-width: 1045px) {
    ${(props) => props.order === 3 && `flex: 0 1 76%;`}
    ${(props) => props.order === 2 && `flex: 0 1 18%;`}
  } */
  @media (max-width: 1359px) {
    ${(props) => props.order === 3 && `margin-top: -25px;`}
  }

  @media (max-width: 950px) {
    ${(props) => props.isHidden && `display: none;`}
  }
`;

const Label = styled.span`
  color: #0c2074;
  font-size: 0.8rem;
  margin: 0 0 5px 5px;
  font-weight: 600;
  width: 120%;
  /* white-space: nowrap; */
  @media (min-width: 950px) {
    ${(props) => props.order === 2 && `width: 350%;`}
    ${(props) => props.order === 3 && `width: 20%;`}
  }

  @media (max-width: 950px) {
    width: 100%;
  }
  span {
    color: red;
  }
`;

const FormLegal = styled.div`
  font-size: 11px;
  /* margin: -10px 0 0 25px; */
  line-height: 1.5;
  order: 99;
  color: #3b3b3b;
  padding-right: 40px;

  a,
  a:visited {
    /* color: white; */
  }
`;
const FormAddon = styled.div`
  font-size: 11px;
  line-height: 1.5;
  color: #3b3b3b;
  padding-right: 40px;
  margin-bottom: 20px;
`;

const RegisterButton = styled(ButtonPrimary)`
  /* margin: 15px 0 45px 0; */
  padding: 10px 55px;
  font-size: max(1rem, 1.2vw);

  @media (max-width: 950px) {
    width: 100%;
  }
`;
const SubHeading = styled.h3``;
const Body = styled.p`
  font-size: max(0.5vw, 0.8rem);
  color: #0c2074;
  margin: -5px 0 0 0;
`;
