import React, { useContext, useState } from "react";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { EventContext } from "context";
import { PrimaryTemplate as Layout } from "Layout/Templates";
import { MaxWidthContainer } from "Layout/MaxWidthContainer";
import { Data } from "./Data";

export const FinancialIQ = () => {
  const { event } = useContext(EventContext);

  const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
  const storageDirectory = event.meta.storageDirectory;

  const { image } = event.communityPartners;

  let partnerLogosImg = `${api}/o/${storageDirectory}%2F${image}?alt=media`;

  const [clicked, setClicked] = useState(false);

  const toggle = (index) => {
    // console.log(clicked, index)
    if (clicked === index) {
      //if clicked question is already active, then close it
      return setClicked(null);
    }

    setClicked(index);
  };

  return (
    <Layout showHeader flexGrow showFooter>
      <PageContent>
        <Heading>Open for small business</Heading>
        <SubHeading title>FAQ's</SubHeading>
        <Accordion>
        {Data.map((item, index) => {
          return (
            <>
              <Wrap onClick={() => toggle(index)} key={index}>
                <SubHeading>{item.question}</SubHeading>
                <span>{clicked === index ? "—" : "+"}</span>
              </Wrap>
              {clicked === index ? (
                <Dropdown>
                  <Body>{ReactHtmlParser(item.answer)}</Body>
                </Dropdown>
              ) : null}
              </>
              );
            })}
            </Accordion>
      </PageContent>
    </Layout>
  );
};

const PageContent = styled(MaxWidthContainer)`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: #fff;
  color: #0c2074;
  padding: 50px 150px;
  background-position: center;
  background-size: cover;
  background-image: url(${(props) => props.backgroundMobile});

  @media ${(props) => props.theme.maxMedia.medium} {
    background-image: url(${(props) => props.backgroundDesktop});
  }
  
  @media (max-width: 950px) {
    padding: 20px;
  }
`;

const Accordion = styled.div`
  border-bottom: 2px solid #b3b3b3;
  width: 100%;
  margin-right: 50px;
`;

const Image = styled.img`
  width: 100%;
  object-fit: cover;
  padding: 0 130px 50px 130px;
`;

const Heading = styled.h2`
  font-size: max(1.2vw, 1.1rem);

  @media (max-width: 950px) {
    font-size: 1.3rem;
  }
`;

const SubHeading = styled.h4`
  font-size: max(1vw, 1rem);
  font-weight: 400;
  color: #0C2074;
  border-top: 2px solid #b3b3b3;
  width: 100%;
  margin: 0px -10px 5px 0;
  padding-top: 22px;
  padding-bottom: 19px;

  @media (max-width: 950px) {
    padding-right: 40px;
  }

  ${(props) => props.title && "border-top: none; margin: -15px 0 10px 2px;"}
`;

const Body = styled.p`
  font-size: max(0.5vw, 0.8rem);
  color: #3b3b3b;
  margin-bottom: 35px;
  margin-top: -5px;
`;

const AccordionSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100vh;
  background: #fff;
`;

const Container = styled.div`
  position: absolute;
  top: 30%;
  box-shadow: 2px 10px 35px 1px rgba(153, 153, 153, 0.3);
`;

const Wrap = styled.div`
  background: #fff;
  color: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: left;
  cursor: pointer;
  h1 {
    padding: 2rem;
    font-size: 0.6rem;
  }
  span {
    margin: 0 10px 0 -15px;
  }
`;

const Dropdown = styled.div`
  background: #fff;
  color: #00ffb9;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  padding-right: 40px;
 
  p {
    font-size: 0.8rem;
    text-align: left;
    width: 100%
  }

  a {
    color: #007079;
  }
`;
