import React, { useContext, useState, useRef } from "react";
import { EventContext, AnalyticsContext, UserContext } from "context";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { PrimaryTemplate as Layout } from "Layout/Templates";
import { MaxWidthContainer } from "Layout/MaxWidthContainer";
import { Data } from "./Data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BusinessSection from "Components/BusinessSection";
import { useMediaQuery } from "hooks/useMediaQuery";

export const SmallBusiness = () => {
  const { event } = useContext(EventContext);
  const { logVideoAction, logClick } = useContext(AnalyticsContext);

  const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
  const storageDirectory = event.meta.storageDirectory;
  const { finance } = event.content;

  const isMobile = useMediaQuery();

  const [clicked, setClicked] = useState(false);

  const linkClickHandler = (url, label, category) => {
    console.log("LABELS", url, label, category);
    logClick({ label, url }, category);
  };

  const toggle = (index) => {
    if (clicked === index) {
      return setClicked(null);
    }
    setClicked(index);
  };

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <Layout showHeader flexGrow showFooter>
      <PageContent>
        <Heading title>Small Business Financial IQ</Heading>
        <Accordion>
          {Data?.map((item, index) => {
            let isDropdown = item.dropdown?.length;
            let image = `https://firebasestorage.googleapis.com/v0/b/whitelabel-set-live.appspot.com/o/658-elavon-musiq-soulchild-2023%2Fsm_b${
              index + 1
            }.png?alt=media`;
            let imageMob = `https://firebasestorage.googleapis.com/v0/b/whitelabel-set-live.appspot.com/o/658-elavon-musiq-soulchild-2023%2Fsm_b${
              index + 1
            }_mob.png?alt=media`;
            return (
              <>
                <Wrap>
                  <img
                    src={isMobile ? imageMob : image}
                    alt=""
                    style={{ width: "100%", marginBottom: 25 }}
                  />
                </Wrap>
                <Wrap>
                  <SubHeading> </SubHeading>
                  <span onClick={() => toggle(index)}>
                    {item.dropdown?.length === 0
                      ? null
                      : clicked === index
                      ? "View less"
                      : "View more"}
                    {isDropdown !== 0 && (
                      <Icon
                        icon={["fa", "chevron-right"]}
                        style={{
                          padding: "2px",
                          height: "15px",
                          width: "15px",
                        }}
                      />
                    )}
                  </span>
                </Wrap>
                <Wrapper>
                  {item.data?.map((item2, index2) => {
                    return (
                      <Content key={index2}>
                        <h3>{item2.headline}</h3>
                        <p>{item2.body}</p>
                        <a
                          href={item2.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() =>
                            linkClickHandler(
                              item2?.link,
                              item2?.label,
                              "external"
                            )
                          }
                        >
                          Learn More{" "}
                          <Icon
                            icon={["fa", "chevron-right"]}
                            style={{
                              padding: "2px",
                              height: "15px",
                              width: "15px",
                            }}
                          />
                        </a>
                      </Content>
                    );
                  })}
                </Wrapper>

                <Wrapper dropdown>
                  {clicked === index
                    ? item.dropdown?.map((item3, index3) => {
                        return (
                          <Content key={index3}>
                            <h3>{item3.headline}</h3>
                            <p>{item3.body}</p>
                            <a
                              href={item3.link}
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={() =>
                                linkClickHandler(
                                  item3?.link,
                                  item3?.label,
                                  "external"
                                )
                              }
                            >
                              Learn More
                              <Icon
                                icon={["fa", "chevron-right"]}
                                style={{
                                  padding: "2px",
                                  height: "15px",
                                  width: "15px",
                                }}
                              />
                            </a>
                          </Content>
                        );
                      })
                    : null}
                </Wrapper>
              </>
            );
          })}
        </Accordion>
      </PageContent>
      <BusinessSection />
    </Layout>
  );
};

const PageContent = styled(MaxWidthContainer)`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: #fff;
  color: #0c2074;
  padding: 50px 150px;
  background-position: center;
  background-size: cover;
  background-image: url(${(props) => props.backgroundMobile});

  @media ${(props) => props.theme.maxMedia.medium} {
    background-image: url(${(props) => props.backgroundDesktop});
  }

  @media (max-width: 950px) {
    padding: 20px;
  }
`;

const Accordion = styled.div`
  width: 100%;
  margin-right: 10px;
`;

const Image = styled.img`
  width: 100%;
  object-fit: cover;
  padding: 0 130px 50px 130px;
`;

const Heading = styled.h2`
  font-size: 1.6rem;
  margin-top: -40px;

  @media (max-width: 950px) {
    font-size: 1.3rem;
    margin: 0px auto 20px;
    padding-bottom: 7px;
    text-align: center;
    border-bottom: 0.5px solid black;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  margin-left: 5px;
  margin-bottom: -2px;
  border: 1px solid #235ae4;
  border-radius: 50px;
`;

const SubHeading = styled.h2`
  font-size: max(1.2vw, 1.1rem);
  color: #0c2074;
  width: 100%;
  font-weight: 900px;
  margin: 0px -10px 5px 0;
  padding: 0px 0 15px 0;

  @media (max-width: 950px) {
    padding-right: 40px;
  }
`;

const Body = styled.p`
  font-size: max(0.5vw, 0.8rem);
  color: #3b3b3b;
`;

const AccordionSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100vh;
  background: #fff;
`;

const Container = styled.div`
  position: absolute;
  top: 30%;
  box-shadow: 2px 10px 35px 1px rgba(153, 153, 153, 0.3);
`;

const Wrap = styled.div`
  background: #fff;
  color: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: left;
  cursor: pointer;
  h1 {
    padding: 2rem;
    font-size: 0.6rem;
  }
  span {
    margin: 0 10px 10px -15px;
    font-size: 0.9rem;
    width: 150px;
    color: #235ae4;
    text-align: right;
  }
  @media (max-width: 950px) {
    flex-direction: column;
    align-items: flex-start;
    span {
      width: fit-content;
      margin: 0;
    }
  }
`;

const Dropdown = styled.div`
  background: #fff;
  color: #00ffb9;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  padding-right: 40px;

  p {
    font-size: 0.8rem;
    text-align: left;
    width: 100%;
  }
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;
  margin: 30px 20px 40px 20px;

  @media (max-width: 950px) {
    display: flex;
    margin: 20px 20px 30px 20px;
    flex-direction: column;
    align-items: center;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 2vw 3vw;
  margin-top: 20px;
  min-height: 400px;
  max-width: 350px;
  /* width: 300px;
  height: 420px; */
  /* min-height: 60%; */
  border: 1px solid black;
  text-align: center;

  & > h3 {
    font-size: max(1.1rem, 1.1vw);
    font-family: "HelveticaNeueBd";
  }

  & > p {
    font-size: max(0.9rem, 0.9vw);
    color: #000000;
  }

  a {
    color: #003cc8;
    font-size: 0.9rem;
    text-decoration: none;
    font-weight: 600;
  }
`;

const BrandLink = styled.a`
  color: #2f75c3;
  text-decoration: none;
`;
const BusinessContent = styled.div`
  display: flex;
  width: 50%;
  margin: auto;
  flex-direction: column;
  padding: 30px 50px 30px 0;

  @media (max-width: 950px) {
  }
`;
