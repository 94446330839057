export const Data = [
  {
    subHeadline: "Starting a Business",
    data: [
      {
        label: "Starting a Business - Starting a business",
        headline:
          "Starting a business? Follow these steps to launch your new career path",
        body:
          "We’ve broken down each step of the process to make your dream a reality.",
        link:
          "https://www.usbank.com/financialiq/manage-your-business/starting-a-business/follow-these-steps-new-career-path.html",
      },
      {
        label: "Starting a Business - Employee to owner",
        headline: "Making the leap from employee to owner",
        body:
          "How one woman overcame her fears of perfection and started her own business. ",
        link:
          "https://www.usbank.com/financialiq/manage-your-business/starting-a-business/making-leap-from-employee-to-owner.html",
      },
      {
        label: "Starting a Business - LLC to S-Corp",
        headline: "From LLC to S-corp: choosing a small business entity",
        body:
          "Need ideas on how to structure your business? Here are some things to consider.",
        link:
          "https://www.usbank.com/financialiq/manage-your-household/protect-your-assets/from-llc-to-s-corp-choosing-a-small-business-entity.html",
      },
    ],
    dropdown: [
      {
        label: "Starting a Business - Tips and advice",
        headline: "Business tips and advice for Black entrepreneurs",
        body:
          "Cory Highshaw shares tips for starting a business to generate wealth for generations.",
        link:
          "https://www.usbank.com/financialiq/manage-your-business/starting-a-business/business-tips-advice-black-entrepreneurs.html",
      },
      {
        label: "Starting a Business - Restaurant startup",
        headline:
          "Planning for restaurant startup costs and when to expect them ",
        body:
          "Are you planning to start your own business? Here’s a quick guide on how to navigate.",
        link:
          "https://www.usbank.com/financialiq/manage-your-business/starting-a-business/Planning-restaurant-startup-costs-when-to-expect-them.html",
      },
    ],
  },
  {
    subHeadline: "Payment Processing",
    data: [
      {
        label: "Payment Processing - For new business",
        headline: "Payment processing for new businesses",
        body:
          "Are you ready to handle payment processing solutions for your new business?",
        link:
          "https://www.elavon.com/resources/insights/payment-processing-for-new-businesses.html",
      },
      {
        label: "Payment Processing - Smart devices",
        headline: "Smart devices create satisfied customers",
        body:
          "How to meet your customer’s needs as commerce and technology evolve.",
        link:
          "https://www.elavon.com/resources/insights/smart-devices-create-satisfied-customers.html",
      },
      {
        label: "Payment Processing - Payment methods",
        headline: "Embracing new payment methods",
        body:
          "Streamlining your business with new and emerging payment methods.",
        link:
          "https://www.elavon.com/resources/insights/embracing-new-payment-methods.html",
      },
    ],
    dropdown: [
      {
        label: "Payment Processing -Business cost",
        topic: "What is the Open For Small Business Program?",
        headline: "How to reduce costs for your small business",
        body:
          "How to reduce costs for your small business in the middle of inflation and labor shortages.",
        link:
          "https://www.elavon.com/resources/insights/how-to-reduce-business-costs.html",
      },
      {
        label: "Payment Processing - Payment fraud",
        topic: "What is the Open For Small Business Program?",
        headline: "How to prevent payment fraud",
        body:
          "Save your money, time, and reputation by learning how to identify payment fraud.",
        link:
          "https://www.elavon.com/resources/insights/how-to-prevent-payment-fraud.html",
      },
    ],
  },
  {
    subHeadline: "Cash Management",
    data: [
      {
        label: "Cash Management - Saving account",
        headline: "How to choose the right business savings account",
        body:
          "A savings account can build confidence in the face of financial downturns or expenses.",
        link:
          "https://www.usbank.com/financialiq/manage-your-business/cash-management/save-smarter--3-steps-to-choosing-a-savings-account-for-your-bus.html",
      },
      {
        label: "Cash Management - Tax deductions",
        headline: "The essential business tips for tax deductions",
        body: "As a business owner, you benefit when you understand taxes.",
        link:
          "https://www.usbank.com/financialiq/manage-your-business/cash-management/the-essential-business-tips-for-tax-deductions.html",
      },
      {
        label: "Cash Management - Business costs",
        headline:
          "Unexpected expenses: 5 small business costs to know and how to finance them",
        body:
          "Here’s what to watch and how to prepare for unexpected expenses.",
        link:
          "https://www.usbank.com/financialiq/manage-your-business/cash-management/Unexpected-expenses-5-small-business-costs-how-to-finance-them.html",
      },
    ],
    dropdown: [
      {
        label: "Cash Management -Checking account",
        topic: "What is the Open For Small Business Program?",
        headline: "How to choose the right business checking account",
        body:
          "Choosing the right checking account for convenience, flexibility, and growth. ",
        link:
          "https://www.usbank.com/financialiq/manage-your-business/cash-management/choose-right-business-checking-account.html",
      },
      {
        label: "Cash Management -Side hustle",
        topic: "What is the Open For Small Business Program?",
        headline: "Side hustle: when to open a new bank account for business",
        body:
          "Here are five reasons why your side hustle deserves its own bank account.",
        link:
          "https://www.usbank.com/financialiq/manage-your-business/cash-management/does-your-side-business-need-a-separate-bank-account.html",
      },
    ],
  },
  {
    subHeadline: "Sales and Marketing",
    data: [
      {
        label: "Sales and Marketing - Marketing Ideas",
        headline:
          "5 affordable small business marketing ideas when you have no budget",
        body:
          "Try these easy, low-cost small business marketing ideas that deliver results.",
        link:
          "https://www.usbank.com/financialiq/manage-your-business/drive-sales/5-affordable-small-business-marketing-ideas-no-budget.html",
      },
      {
        label: "Sales and Marketing - Online presence",
        headline: "Improve the online presence of your business",
        body:
          "The right solutions for expanding your small business’ online presence.",
        link:
          "https://www.usbank.com/financialiq/manage-your-business/build-a-brand/Improve-online-presence-your-business.html",
      },
      {
        label: "Sales and Marketing - POS data",
        headline: "3 ways POS data analysis can help define your brand",
        body:
          "Three ways POS analytics and reports can serve businesses and customers.",
        link:
          "https://www.usbank.com/financialiq/manage-your-business/build-a-brand/3-ways-POS-data-analysis-can-help-define-your-brand.html",
      },
    ],
    dropdown: [
      {
        label: "Sales and Marketing - Restaurant models",
        headline: "4 restaurant models that aren’t dine-in",
        body:
          "Explore four service models that veer from traditional brick-and-mortar stores.",
        link:
          "https://www.usbank.com/financialiq/manage-your-business/drive-sales/4-restaurant-models-that-arent-dine-in.html",
      },
      {
        label: "Sales and Marketing - Ecommerce strategy",
        headline: "Why ecommerce for small business strategy is integral",
        body:
          "The importance of a strong ecommerce presence in a digital world.",
        link:
          "https://www.usbank.com/financialiq/manage-your-business/drive-sales/Why-ecommerce-for-small-business-strategy-integral.html",
      },
    ],
  },
];
