import { useContext, useState, useEffect } from "react";
import { Duration } from "luxon";
import { EventContext, TimeContext, AnalyticsContext } from "context";
import { PrimaryTemplate as Layout } from "Layout/Templates";
import { HeroWrapper, HeroContainer } from "Layout/Hero";
import { Countdown } from "Components";

export const PreShow = () => {
  const { event } = useContext(EventContext);
  const { time } = useContext(TimeContext);
  const { logLeadSubmission } = useContext(AnalyticsContext);

  const [eventFired, setEventFired] = useState(false);
  const params = new URLSearchParams(window.location.search);
  const utm_source = params.get("utm_source");

  useEffect(() => {
    if (utm_source === "oauth" && !eventFired) {
      setEventFired(true);
      logLeadSubmission({ formPart: 0 });
    }
    // eslint-disable-next-line
  }, []);

  // const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
  // const storageDirectory = event.meta.storageDirectory;

  // const { backgrounds } = event.register;
  // const backgroundImg = `${api}/o/${storageDirectory}%2F${backgrounds[0]}?alt=media`;

  const timeToStart =
    time < event?.start &&
    Duration.fromMillis(event?.start - time).shiftTo(
      "days",
      "hours",
      "minutes",
      "seconds",
      "milliseconds"
    );

  const timer = {
    label: event?.preShow?.countdownLabel ?? "Show starts in",
    days: timeToStart.days,
    hours: timeToStart.hours,
    minutes: timeToStart.minutes,
    seconds: timeToStart.seconds,
  };

  return (
    <Layout
      // header
      showHeader
      // main
      heroContent={
        <HeroWrapper>
          <HeroContainer>
            <Countdown {...timer} />
          </HeroContainer>
        </HeroWrapper>
      }
      heading={event?.preShow?.heading}
      subHeading={event.preShow?.subHeading}
      body={event?.preShow?.body}
      flexGrow
      // footer
      showFooter
    />
  );
};
