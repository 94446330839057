import React, { useContext, useState, useRef } from "react";
import { EventContext, AnalyticsContext, UserContext } from "context";
import styled, { css } from "styled-components";
import { Link, useRouteMatch, useHistory, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMediaQuery } from "hooks/useMediaQuery";
// import { VideoModal } from "Components/Media";
import {
  Modal,
  Media16By9,
  MediaVideo,
  HeroVideo,
  ButtonPrimary,
} from "Components";

const RightNav = ({ open: isOpen }) => {
  // const [ modalOpen, setModalOpen ] = useState(false)
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  const [video, setVideo] = useState(null);
  const { logVideoAction, logClick } = useContext(AnalyticsContext);

  const linkClickHandler = (url, label, category) => {
    logClick({ label, url }, category);
  };

  const setModalVisible = (bool, link) => {
    setVideo(link);
    setOpen(bool);
    logClick(
      {
        link: "https://www.youtube.com/embed/rD28iFHhZRk",
        label: "Hero Video",
      },
      "internal"
    );
  };
  const isMobile = useMediaQuery();
  return (
    <>
      {/* {!isMobile && (
        <Modal open={open} setOpen={setModalVisible}>
          <Media16By9>
            <MediaVideo
              src={video}
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </Media16By9>
        </Modal>
      )} */}
      <Ul open={isOpen}>
        {(pathname === "/small-business-iq" ||
          pathname === "/small-business-spotlights") && (
          <CustomLink
            to="/"
            onClick={() =>
              linkClickHandler(
                "https://elavonforsmallbusiness.com",
                "registration page",
                "header"
              )
            }
          >
            Nominate a Small Business
          </CustomLink>
        )}
        <CustomLink
          to="/small-business-iq"
          onClick={() =>
            linkClickHandler(
              "https://elavonforsmallbusiness.com/small-business-iq",
              "Small Business Financial IQ",
              "header"
            )
          }
        >
          Small Business <span>Financial IQ</span>
        </CustomLink>
        <CustomLink
          to="/small-business-spotlights"
          onClick={() =>
            linkClickHandler(
              "https://elavonforsmallbusiness.com/small-business-spotlights",
              "Small Business Spotlights",
              "header"
            )
          }
        >
          Small Business Spotlights
        </CustomLink>

        {/* {!isMobile && (
          <WatchBtn
            onClick={() =>
              setModalVisible(true, "https://www.youtube.com/embed/rD28iFHhZRk")
            }
          >
            Watch Our Featured Businesses
          </WatchBtn>
        )} */}
      </Ul>
    </>
  );
};

export default RightNav;

function CustomLink({ to, children, ...props }) {
  const isMobile = useMediaQuery();
  const resolvedPath = useHistory(to);
  const isActive = useRouteMatch({ path: resolvedPath.pathname, end: true });
  const { pathname } = useLocation();
  console.log("active", pathname, to);
  return (
    <li>
      <StyledLink to={to} {...props} active={pathname === to}>
        {children}
      </StyledLink>
      {isMobile && <FontAwesomeIcon icon={["fa", "chevron-right"]} />}
    </li>
  );
}
// export default RightNav;

const Ul = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  li {
    padding: 18px 10px;
    font-size: min(1.3vw, 1rem);
    text-decoration: none;
    color: #0c2074;
    letter-spacing: 0.02rem;

    & > svg {
      color: #0c2074;
    }

    & > a {
      text-decoration: none;
      color: #0c2074;
      //font-weight: 600;
    }
  }
  @media (max-width: 1130px) {
    flex-flow: column nowrap;
    background-color: #fff;
    position: fixed;
    align-items: stretch;
    transform: ${(props) =>
      props.open ? "translateX(0)" : "translateX(100%)"};
    top: -5%;
    right: 0;
    height: 120%;
    width: 80%;
    padding: 150px 70px 0 50px;
    transition: transform 0.3s ease-in-out;
    text-decoration: none;
    li {
      color: #2f75c3;
      border-bottom: 1px solid #ddd;
      padding-top: 30px;
      font-size: 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      & > a {
        text-decoration: none;
        font-weight: 600;
        padding-right: 20px;
      }
    }
  }
  ${({ active }) =>
    active &&
    css`
      li {
        text-decoration: underline;
      }
    `}
`;

const WatchBtn = styled.button`
  background-color: #2f75c3;
  color: white;
  font-size: max(0.6vw, 0.9rem);
  border-radius: 10px;
  border: 1px solid #2f75c3;
  padding: 0 15px;
  height: 35px;
  font-weight: 500;
  text-align: center;
`;
const StyledLink = styled(Link)`
  ${({ active }) =>
    active &&
    css`
      text-decoration: underline !important;
    `}
  span {
    white-space: nowrap;
  }
`;
