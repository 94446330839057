import React, { useContext } from "react";
import styled from "styled-components";
import Burger from "./Burger";
import { AnalyticsContext } from "context";

export const Navbar = ({ brandLogoImg, brandLogo }) => {
  const { logClick } = useContext(AnalyticsContext);
  const linkClickHandler = (url, label, category) => {
    logClick({ label, url }, category);
  };
  return (
    <Nav>
      <Link
        href={brandLogo}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() =>
          linkClickHandler(
            "https://elavonforsmallbusiness.com",
            "Homepage",
            "header"
          )
        }
      >
        {brandLogoImg && <Logo src={brandLogoImg} />}
      </Link>
      <Burger />
    </Nav>
  );
};

const Nav = styled.nav`
  width: 90%;
  padding: 0 50px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 99;
`;

const Link = styled.a`
  margin-top: 10px;
  width: min(15vw, 250px);

  @media (max-width: 950px) {
    width: 190px;
    padding-bottom: 20px;
  }
`;

const Logo = styled.img`
  width: 100%;
  display: flex;
  margin-top: -20px;

  @media (max-width: 950px) {
    margin: -10px;
  }
`;
