import styled from "styled-components";
import { Select as Select2 } from "../Select";
import { FieldMessage } from "Components";
import { useMediaQuery } from "hooks/useMediaQuery";

export const Select = ({ onChange, value, field, error = {} }) => {
  let selected = value && field.options.find((option) => option.id === value);
  const isMobile = useMediaQuery();
  const handleChange = (value) => {
    onChange({ [field.name]: value });
  };

  return (
    <>
      {/* {!error.status && value && (
        <FieldMessage
          content={error.message}
          type={error.status ? "error" : "success"}
          style={{
       
          }}
          field={field}
        />
      )} */}
      <StyledSelect
        placeholder={field.placeholder ?? "Select an option..."}
        selected={selected}
        options={field.options}
        error={!!error.status}
        isMobile={!isMobile}
        onChange={(val) => handleChange(val.id)}
      />
      {error.status && (
        <FieldMessage
          active={value || !!error.status}
          content={error.message}
          type={error.status ? "error" : "success"}
        />
      )}
    </>
  );
};

const primaryColor = "#6E6E6E";
const borderRadius = "7px";
const errorBackground = "#EDB8B4";
const errorBorderColor = "#E45C52";
const errorFieldColor = "#6d2621";

const StyledSelect = styled(Select2)`
  border-radius: ${borderRadius};
  border: 1px solid #4580dd;
  background-color: white;

  /* ${(props) => props.isMobile && "width: 170px; height: 41px;"} */

  span {
    font-size: 12px;
    color: #3B3B3B;
    font-weight: 500;
    
  }

  & > svg > path {
    fill: ${primaryColor};
  }

  ${(props) =>
    props.error &&
    `
    background-color: ${errorBackground};
    border: 2px solid ${errorBorderColor};

    span {
      color: ${errorFieldColor};
    }

    &:focus {
      border-width: 1px;
    }
  `};
`;
