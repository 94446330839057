import React, { useContext, useState } from "react";
import styled from "styled-components";
import { EventContext, AnalyticsContext } from "context";
import { MaxWidthContainer } from "./MaxWidthContainer";
import { useMediaQuery } from "hooks/useMediaQuery";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { Navbar } from "Components/Navbar/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Modal,
  Media16By9,
  MediaVideo,
  HeroVideo,
  ButtonPrimary,
} from "Components";
library.add(fas, fab);

export const Header = ({ showHeader, registration, preCampaign }) => {
  const { event } = useContext(EventContext);
  const { logClick } = useContext(AnalyticsContext);

  const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
  const storageDirectory = event.meta.storageDirectory;

  const isMobile = useMediaQuery();

  const {
    brand: brandLogo,
    // event: eventLogo,
    // secondary: secondaryLogo,
  } = event.logos;

  let brandLogoImg = `${api}/o/${storageDirectory}%2F${brandLogo?.image}?alt=media`;

  const linkClickHandler = (url, label, category) => {
    logClick({ label, url }, category);
  };

  const [open, setOpen] = useState(false);
  const [video, setVideo] = useState(null);

  const setModalVisible = (bool, link) => {
    setVideo(link);
    setOpen(bool);
    logClick(
      {
        link: "https://www.youtube.com/embed/rD28iFHhZRk",
        label: "Hero Video",
      },
      "internal"
    );
    //  logVideoAction({ bool, videoLink: link, videoTitle: "Hero Video" });
  };

  return (
    <>
      {showHeader && (
        <HeaderWrapper as="header" registration={registration}>
          <HeaderContainer preCampaign={preCampaign}>
            {preCampaign ? (
              <Link
                href={brandLogo.link}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() =>
                  linkClickHandler(brandLogo.link, "Elavon Homepage", "header")
                }
              >
                {brandLogoImg && <PrimaryLogo src={brandLogoImg} />}
              </Link>
            ) : (
              <Navbar brandLogo={brandLogo.link} brandLogoImg={brandLogoImg} />
            )}
          </HeaderContainer>
          {isMobile && (
            <Modal open={open} setOpen={setModalVisible}>
              <Media16By9>
                <MediaVideo
                  src={video}
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                />
              </Media16By9>
            </Modal>
          )}
        </HeaderWrapper>
      )}
    </>
  );
};

const HeaderWrapper = styled.header`
  z-index: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
  z-index: 99;
  @media ${(props) => props.theme.maxMedia.medium} {
    padding: 0;
  }
`;

const HeaderContainer = styled(MaxWidthContainer)`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex: 1;
  background-color: #fff;
  padding: 20px 0px 10px 0px;
  font-size: 0.95rem;

  ${(props) => props.preCampaign && "padding-left: 60px"}

  ul {
    gap: 3vw;
    list-style: none;
    display: flex;
  }
`;

const VideoContainer = styled.div`
  background-color: #2f75c3;
  color: white;
  font-size: max(0.8rem, 2.5vw);
  text-align: center;
  padding: 7px 0;
  cursor: pointer;
`;

const Link = styled.a`
  width: 100%;
  max-width: 150px;
`;

const Logo = styled.img`
  width: 100%;
  display: flex;
  margin-top: -10px;
`;

const PrimaryLogo = styled(Logo)``;
