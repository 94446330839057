import React, { useContext, useState, useRef } from "react";
import { EventContext, AnalyticsContext, UserContext } from "context";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonPrimary } from "Components/Buttons";

const BusinessSection = () => {
  const { event } = useContext(EventContext);
  const { logVideoAction, logClick } = useContext(AnalyticsContext);
  const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
  const storageDirectory = event.meta.storageDirectory;
  const { images } = event.content;

  const linkClickHandler = (url, label, category) => {
    console.log("LABELS", url, label, category);
    logClick({ label, url }, category);
  };

  let img4 = `${api}/o/${storageDirectory}%2F${images.img4}?alt=media`;
  return (
    <BusinessWrapper>
      <BusinessContent>
        <Heading>Open for small business</Heading>
        <Body>
          For more than 30 years, we’ve been a global leader in payment
          processing for businesses of every size. We know that growing your
          business takes knowledge, persistence, and determination. So we’ve
          developed a broad range of scalable payment solutions tailored to your
          needs.
          <br />
          <br />
          Discover how Elavon empowers businesses with exceptional experiences.
        </Body>
        <BrandLink
          onClick={() => {
            window.open(
              "https://www.elavon.com/your-business/small-business.html",
              "_blank",
              "noopener,noreferrer"
            );
            linkClickHandler(
              "https://www.elavon.com/your-business/small-business.html",
              "Learn More, CTA Small Business",
              "external"
            );
          }}
        >
          Learn More
        </BrandLink>
      </BusinessContent>
      <img src={img4} alt="small business illustration" />
    </BusinessWrapper>
  );
};

export default BusinessSection;

const Icon = styled(FontAwesomeIcon)`
  margin-left: 5px;
  margin-bottom: -2px;
  border: 1px solid #007079;
  border-radius: 50px;
`;

const Heading = styled.h2`
  font-size: max(1.3vw, 1.1rem);

  @media (max-width: 950px) {
    font-size: 1.8rem;
  }
`;

const BusinessWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 80%;
  margin: 0 auto;
  gap: 30px;
  img {
    width: 50%;
    padding: 50px 50px 0 0;
  }
  @media (max-width: 950px) {
    flex-direction: column;
    width: 100%;

    img {
      width: 85%;
      margin: 0 auto;
      padding: 35px 35px 0;
    }
  }
`;
const BrandLink = styled(ButtonPrimary)`
  margin: 20px auto 0 0;
  /* color: #2f75c3;
  text-decoration: none; */
`;
const BusinessContent = styled.div`
  display: flex;
  width: 50%;
  margin: auto;
  flex-direction: column;
  padding: 30px 50px 30px 0;

  @media (max-width: 950px) {
    width: 80%;
    padding-left: 30px;
  }
`;
const Body = styled.p`
  font-size: max(0.5vw, 0.8rem);
  color: #00095b;
`;
