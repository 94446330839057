import React, { useContext, useState, useRef } from "react";
import { EventContext, AnalyticsContext, UserContext } from "context";
import styled from "styled-components";
import { ButtonPrimary } from "Components/Buttons";
import ReactHtmlParser from "react-html-parser";
import { PrimaryTemplate as Layout } from "Layout/Templates";
import { MaxWidthContainer } from "Layout/MaxWidthContainer";
import { useMediaQuery } from "hooks/useMediaQuery";
import HorizontalScroll from "react-scroll-horizontal";
import BusinessSection from "Components/BusinessSection";

export const Spotlights = () => {
  const { event } = useContext(EventContext);
  const { logVideoAction, logClick } = useContext(AnalyticsContext);

  const isMobile = useMediaQuery();
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const linkClickHandler = (url, label, category) => {
    console.log("LABELS", url, label, category);
    logClick({ label, url }, category);
  };

  const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
  const storageDirectory = event.meta.storageDirectory;

  const { spotlights } = event.content;
  console.log(
    "sp",
    `${api}/o/${storageDirectory}%2F${spotlights.img1}?alt=media`
  );
  // let img1 = `${api}/o/${storageDirectory}%2F${spotlights.img1}?alt=media`;

  const {
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
  } = event.communityPartners;

  return (
    <Layout showHeader flexGrow showFooter>
      <Content>
        <Heading>Small Business Spotlights</Heading>
        {spotlights?.map((b, i) => (
          <WrapperCTA>
            <ContentCTA>
              <div id={i === 0 ? "isFirst" : ""}>
                <h2>{b?.headline}</h2>
                <p>{b?.p}</p>
                <Button
                  onClick={() => {
                    window.open(b?.link, "_blank", "noreferrer");
                    linkClickHandler(
                      b?.link,
                      `Spotlight ${b?.headline}`,
                      "external"
                    );
                  }}
                >
                  Learn more
                </Button>
              </div>
            </ContentCTA>
            <img
              src={`https://firebasestorage.googleapis.com/v0/b/whitelabel-set-live.appspot.com/o/658-elavon-musiq-soulchild-2023%2F${b?.img}?alt=media`}
              alt=" "
            />
          </WrapperCTA>
        ))}
        <BusinessSection />
      </Content>
    </Layout>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1450px;
  margin: 0 auto;
  overflow: hidden;
`;
const ContentCTA = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  & > div {
    margin: auto auto auto 100px;
    width: 70%;
  }
  @media (max-width: 950px) {
    padding: 50px 0;
    margin: 0 auto;
    & > div {
      margin: auto;
      width: 80%;
    }
    & > div#isFirst {
      margin: auto;
      width: 80%;
      padding-top: 30px;
      border-top: 1px solid #707070;
    }
  }
`;
const WrapperCTA = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  margin: 20px auto;
  //border-top: 1px solid #707070;
  img {
    width: 92.5%;
    object-fit: cover;
    margin-right: 50px;
  }
  @media (max-width: 950px) {
    grid-template-columns: 1fr;
    margin: 0px auto 0;
    img {
      width: 100%;
      margin-right: 0px;
    }
  }
`;
const Button = styled(ButtonPrimary)`
  margin-top: 10px;
  width: 60%;
  @media (max-width: 950px) {
    width: 100%;
  }
`;

const PageContent = styled(MaxWidthContainer)`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: #fff;
  color: #0c2074;
  padding: 50px 150px;
  background-position: center;
  background-size: cover;
  background-image: url(${(props) => props.backgroundMobile});

  @media ${(props) => props.theme.maxMedia.medium} {
    background-image: url(${(props) => props.backgroundDesktop});
  }

  @media (max-width: 950px) {
    padding: 20px 30px;
  }
`;

const Image = styled.img`
  width: 25%;
  /* object-fit: cover; */
  /* padding: 0 130px 50px 130px; */

  @media (max-width: 950px) {
    width: 100%;
  }
`;

const Image2 = styled.img`
  width: 80%;
`;

const Heading = styled.h2`
  font-size: 1.6rem;
  padding: 0 0px 20px 0px;
  margin: 0 50px 20px 100px;
  border-bottom: 1px solid #707070;

  @media (max-width: 950px) {
    // font-size: 1.4rem;
    padding: initial;
    margin: 25px auto -40px;
    border-bottom: 0px solid #707070;
  }
`;

const Body = styled.p`
  font-size: max(0.5vw, 0.8rem);
  color: #3b3b3b;
  padding: 0 50px;

  @media (max-width: 950px) {
    font-size: 1rem;
    padding: initial;
  }
`;

const LogoWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 80%;

  div {
    display: flex;
    flex-direction: row;
    padding: 20px;
    align-items: center;

    & > img {
      cursor: pointer;
    }
  }

  @media (max-width: 950px) {
    /* display: none; */
    flex-direction: row;
    width: 90%;

    div {
      width: 50%;
      flex-wrap: wrap;
      flex-direction: column;
    }
  }
`;

const LogoWrapMobile = styled.div`
  display: flex;
  flex-direction: column;

  div {
    display: flex;
    flex-direction: row;
  }
`;
