import React from "react";
import styled from "styled-components";
import ReactPlayer from "react-player";

export const VideoPlayer = ({
  play = false,
  path,
  loop = false,
  mute = false,
  isBackground = false,
  onEnded,
  thumbnail,
  ...props
}) => {
  const handleEnd = () => {
    !!onEnded && onEnded();
  };

  const options = {
    controls: !isBackground,
    disablekb: isBackground ? 1 : 0,
    loop: loop,
    muted: mute,
    playing: play,
    url: path,
    volume: mute ? 0 : 1,
    width: "100%",
    height: "100%",
    playsinline: isBackground ? true : false,
    config: {
      file: {
        hlsVersion: "0.14.17",
        hlsOptions: {
          maxMaxBufferLength: 10,
        },
        attributes: {
          // <video> element attributes
          poster: thumbnail ?? null,
        },
      },
    },
    onEnded: handleEnd,
  };

  return <StyledReactPlayer {...options} {...props} />;
};

const StyledReactPlayer = styled(ReactPlayer)`
  /* position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1; */
  video {
    outline: none;
  }
`;
